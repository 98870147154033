<template>
	<div class="menu">
		<page-title />
		<ul>
			<li v-if="page.slug == 'home'">
				<button @click="store.dispatch('program/showInstructions', true)"><span class="menu-number">1</span> Getting Started</button>
			</li>
			<li v-for="child, idx in page.children" :key="child.id">
				<button @click="store.dispatch('program/changePage', child.id)" 
					v-html="(page.slug == 'home' ? `<span class='menu-number'>${idx + 2}</span>` : '') + child.title">
				</button>
			</li>
			<li v-if="page.slug == 'home'">
				<button @click="() => { router.push('/effectively') }"><span class="menu-number">{{ page.children.length + 2 }}</span> Speaking Effectively</button>
			</li>
			<li v-if="page.slug == 'home'">
				<button @click="() => { router.push('/certificate') }"><span class="menu-number">{{ page.children.length + 3 }}</span>
          Awarding Certificate</button>
			</li>
		</ul>
		<description />
	</div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import { useRouter } from 'vue-router';
const { page, store } = defineProps(['page','store']);
const router = useRouter();
</script>

<style lang="scss" scoped>
	.menu {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
		}

		button {
			margin: .75rem 0;
			min-width: 260px;
		}
	}
</style>

<style lang="css">
span.menu-number {
	border: 1px solid black;
	border-radius: 50%;
	position: absolute;
	left: -40px; top: 50%;
	line-height: 30px;
	transform: translateY(-50%);
	width: 30px;
}
</style>