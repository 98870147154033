<template>
	<div class="program-map">
		<div>
			<page-title>Program Map</page-title>
			<ul v-if="active">
				<map-tree v-for="item in map" :key="item.id" :node="item" :top="true" :topId="item.id"></map-tree>


				<li v-if="showInit" style="margin-top:.5rem;">
					<h3>
						<a @click.prevent="showList = !showList;">Speaking Effectively <i :class="'fal '+ (showList ? 'fa-angle-down' : 'fa-angle-right')"></i></a>
					</h3>
					<ul v-if="showList">
						<li>
							<strong><a class="button-link" href="https://www.youtube.com/playlist?list=PLAkHn0khMM2FpAgiRUnSKdrVaSJDse5Ts" target="_blank">Male TED Talks</a></strong>
						</li>
						<li>
							<strong><a class="button-link" href="https://www.youtube.com/playlist?list=PLAkHn0khMM2H1EdmJrf4AyTHSwUVjwerv" target="_blank">Female TED Talks</a></strong>
						</li>
					</ul>
				</li>
			</ul>
			<expire-msg v-else />
		</div>
	</div>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import MapTree from '@/components/MapTree';
import ExpireMsg from '@/components/ExpireMsg';
const store = useStore();
const map = computed(() => store.getters['program/getMap']);
const active = computed(() => store.getters['auth/isActive']);

watch(map, (newVal, oldVal) => {
	if(newVal.length > 0) showInit.value = true;
})

const showList = ref(false);
const showInit = ref(false);


onMounted(() => {
	if(!map) store.dispatch('setMap');
});
</script>

<style lang="scss" scoped>
.program-map {
	background-color: $white;;
}

h3 {
	line-height: 1.5;
	margin-bottom: .5rem;

	i.fal {
		padding-left: 1rem;
		color: $primary;
	}

	a {
		padding: .25rem 0;
		cursor: pointer;
	}
}

ul {
	list-style: none;
}

ul > li {
	border-left: 0;
	padding-left: 1rem;

	&:before {
		display: none;
	}
}

li ul li {
  --spacing : .5rem;
  --radius  : 10px;
}
	li ul {
		list-style: none;
		margin-left  : calc(var(--radius) - var(--spacing));
		padding-left : 0;

		li {
			border-left : 2px solid #ddd;
			display      : block;
			position     : relative;
			padding-left : calc(2 * var(--spacing));
			
			&:before {
				content      : '';
				display      : block;
				position     : absolute;
				top          : calc(var(--spacing) / 2);
				left         : -2px;
				width        : calc(var(--spacing) + 2px);
				height       : calc(var(--spacing) + 1px);
				border       : solid #ddd;
				border-width : 0 0 2px 2px;
			}

			&:last-child{
				border-color : transparent;

				&:before {
					height       : calc(var(--spacing) + 4px);
    			top: calc(var(--spacing) / 2 - 4px);
				}
			}
		}

		a {
			cursor: pointer;
			display: inline-block;
			padding: .25rem 0;
		}
	}
</style>