<template>
  <div>
    <section class="cert">
      <h1>Awarding Certificate</h1>
      <p>A certificate is awarded after successfully demonstrating your ability to read a five minute Ted Talk speech (of your choice) to a reviewer via Zoom.</p>
      <p>The steps are as follows:</p>
      <ol>
        <li>
          <p>Send an email to the Ford Language Institute requesting an oral review - provide the best times and dates.</p>
          <p>The reviewer will email with confirmation and contact you on the agreed day and time for a zoom review.</p>
        </li>
        <li>Upon successfully completing the review, the reviewer will provide a password to access the certificate.</li>
        <li>Add your name and print.</li>
        <li>Proudly display on your wall, show your friends and especially your supervisor!</li>
        <li>Congratulations - you have achieved the ultimate distinction as an Advanced Speaker of American English.</li>
      </ol>
      <button @click="router.push('/')"><i class="fa fa-chevron-left"></i> &nbsp; Back</button>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PageService from '@/services/PageService';
import MiniLoad from "@/components/MiniLoader.vue";

const store = useStore();
const router = useRouter();

</script>

<style lang="scss" scoped>
.cert {
		background-color: $white;
		padding: 1.5rem 1rem;

    h1 {
      margin-bottom: .5rem;
      text-align: center;
    }

		button {
			background-color: $primary;
			border: 0;
			border-radius: 10px;
			color: $white;
			padding: .5rem .75rem;
      margin-top: .5rem;
		}

		h4 {
			margin-top: 0;
		}

		li {
			margin-bottom: .5rem;
		}
	}
</style>